// Includes foundational settings
@import "1_settings/includes--base";

// LIVE WIRES
//
// By default, these styles simply block out any text inside of a content l* element.
// Add any of your own wireframe-specific styles here.

// Block out all p,li,dd text inside of .content elements
.content{
  p,
  li,
  dd {
    line-height: 1.25;
    letter-spacing:-0.1em;
    word-spacing: -0.9em;
    font-family: "BLOKKNeue";
    color: #BABAAE;

    > * {
      word-spacing: 0;
      letter-spacing: 0;
    }

  }
}

// Force clear text
.callout--clear .content,
.content--clear {
    p,
    li,
    dd {
      line-height: 1.5;
      letter-spacing:0;
      word-spacing: 0;
      font-family: inherit;
      color: inherit;
  }
}


// If you don't have content, shame on you.
// Here's a little handy set of classes to help you anyway.
// Just add a line class to your container and we'll fill it with the text placeholder image.
//
// eg) class="l5" would render 5 lines of text placeholder images
@for $i from 1 through 100 {
  .l#{$i} { height:$line* $i * 1px; background: url('../img/livewires/text.png'); }
  .l#{$i} > p { visibility: hidden; }
}
