// FONTS

// BLOKK Face for wireframing
@font-face {
  font-family: 'BLOKKNeue';
  src: url('../fonts/BLOKKNeue-Regular.eot');
  src: url('../fonts/BLOKKNeue-Regular.eot?#iefix') format('embedded-opentype'),
   url('../fonts/BLOKKNeue-Regular.woff') format('woff'),
   url('../fonts/BLOKKNeue-Regular.svg#BLOKKNeue-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}